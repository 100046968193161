<template>
  <v-app light>
    <v-main>
      <div class="container">
        <!-- title bar -->
        <v-card
          color="grayoil"
          class="header pa-4 my-2 d-flex flex-row justify-space-between"
          elevation="0"
        >
          <div class="d-flex flex-row">
            <div class="mr-3">
              <v-icon large color="white"> mdi-water-opacity </v-icon>
            </div>
            <div class="d-flex flex-column">
              <h2>Short Term Prediction</h2>
              <div>
                {{ title }}
              </div>
            </div>
          </div>

          <div>
            <a href="/msp/oilspill" target="_blank">
              <v-btn x-large color="primary" dark> สร้างไฟล์ NC </v-btn>
            </a>
          </div>
        </v-card>

        <!-- selection panal -->
        <v-card class="d-flex flex-row pa-12 mb-4" elevation="0">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <h3>เลือกประเภทกระแสน้ำ/ลม</h3>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="itemsCurrent"
                    v-model="typeCurrent"
                    item-text="name"
                    item-value="value"
                    tex
                    label="ประเภทกระแสน้ำหรือลม"
                    dense
                    solo
                  ></v-select>
                </v-col>
              </v-row>
              <h3 class="mb-4">เลือกช่วงเวลา</h3>

              <div class="d-flex flex-column pa-0">
                <!-- date / time -->
                <v-row class="mb-2">
                  <v-col cols="12" sm="12" md="12">
                    <vc-date-picker
                      v-model="range"
                      mode="dateTime"
                      is-range
                      is24hr
                      :update-on-input="false"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="d-flex justify-center items-center">
                          <v-text-field
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                            filled
                            prepend-inner-icon="mdi-calendar"
                            hide-details
                            class=""
                          />
                          <v-icon> mdi-arrow-right-thin </v-icon>
                          <v-text-field
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                            filled
                            prepend-inner-icon="mdi-calendar"
                            hide-details
                            class=""
                          />
                        </div>
                      </template>
                    </vc-date-picker>
                  </v-col>
                </v-row>

                <div class="d-flex justify-space-between my-4">
                  <h3 class="my-4">ขอบเขต</h3>
                  <v-btn-toggle
                    v-model="activeTab"
                    class="rounded-md border"
                    mandatory
                    color="primary"
                    group
                  >
                    <v-btn
                      value="province"
                      :class="activeTab === 'province' ? 'text-white' : ''"
                      @click="activeTab = 'province'"
                    >
                      จังหวัด
                    </v-btn>
                    <v-btn
                      value="bbox"
                      :class="activeTab === 'bbox' ? 'text-white' : ''"
                      @click="activeTab = 'bbox'"
                    >
                      BBOX
                    </v-btn>
                  </v-btn-toggle>
                </div>

                <div v-show="activeTab === 'province'">
                  <v-row class="d-flex justify-end">
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        v-model="provinceSelected"
                        :items="provinceList"
                        item-text="name"
                        return-object
                        label="Province"
                        solo
                        hide-details
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </div>

                <!-- BBox Draw Polygon -->
                <div v-show="activeTab === 'bbox'">
                  <v-row class="d-flex justify-end">
                    <v-btn class="" @click="drawRectangle">
                      <div
                        style="
                          background-color: blue;
                          width: 10px;
                          height: 10px;
                          border-radius: unset;
                        "
                        class="mr-2"
                      ></div>
                      <div>Draw Polygon</div>
                    </v-btn>
                  </v-row>

                  <!-- North Latitude -->
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="North Latitude"
                        v-model="locationBound.maxLat"
                        filled
                        prepend-inner-icon="mdi-arrow-up-thin"
                        type="number"
                        hide-details
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- West Longitude and East Longitude -->
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" sm="5" md="5">
                      <v-text-field
                        label=" West Longitude"
                        v-model="locationBound.minLon"
                        filled
                        prepend-inner-icon="mdi-arrow-left-thin"
                        type="number"
                        hide-details
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="5" md="5">
                      <v-text-field
                        label=" East Longitude"
                        v-model="locationBound.maxLon"
                        filled
                        prepend-inner-icon="mdi-arrow-right-thin"
                        type="number"
                        hide-details
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- South Latitude -->
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        label="South Latitude"
                        v-model="locationBound.minLat"
                        filled
                        prepend-inner-icon="mdi-arrow-down-thin"
                        type="number"
                        hide-details
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <v-divider class="my-5"></v-divider>

                <div class="d-flex justify-end">
                  <!-- to GeoJson -->
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-show="isDownload"
                  ></v-progress-circular>
                  <v-btn
                    @click="downloadGeoJsonFile()"
                    class="mb-4 mx-2"
                    depressed
                    color="primary"
                    large
                    :dark="!isDownload"
                    :disabled="isDownload"
                    >{{ isDownload ? "Processing" : "GeoJson"
                    }}<v-icon right v-show="!isDownload">
                      mdi-content-save
                    </v-icon></v-btn
                  >
                  <!-- to Graph  -->
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-show="isDownload"
                  ></v-progress-circular>
                  <v-btn
                    @click="createGraph()"
                    class="mb-4"
                    depressed
                    color="primary"
                    large
                    :dark="!isDownload"
                    :disabled="isDownload"
                    >{{ isDownload ? "Processing" : "Create Graph"
                    }}<v-icon right v-show="!isDownload"> mdi-chart-line </v-icon></v-btn
                  >
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="6" style="position: relative; min-height: 500px">
              <longdo-map @load="init" :zoom="8" :location="locTrat" />

              <v-card
                style="position: absolute; padding: 8px; bottom: 60px; right: 20px"
                class="d-flex align-center"
                elevation="0"
              >
                <div
                  style="
                    background-color: red;
                    width: 2px;
                    height: 10px;
                    border-radius: unset;
                  "
                  class="mr-2"
                ></div>
                <div style="font-size: 12px">พื้นที่รับสัญญาณเรดาร์ชายฝั่ง สทอภ.</div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>

        <v-row style="min-height: 600px">
          <v-col cols="12" sm="12" md="12">
            <v-card class="d-flex flex-column pa-0 mb-4" elevation="2">
              <v-card class="py-2 px-4 rounded-b-0" color="subcolor" dark elevation="0">
                <h3>ผลลัพธ์</h3>
              </v-card>
              <div class="pa-6">
                <!-- <ArrowChart :oilData="oil_resp" /> -->
                <VelocityDirectionChart :oilData="oil_resp" />
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <v-card class="d-flex flex-column pa-0 mb-4" elevation="2">
              <v-card class="py-2 px-4 rounded-b-0" color="subcolor" dark elevation="0">
                <h3>ผลลัพธ์</h3>
              </v-card>
              <v-simple-table class="pa-6">
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header">{{ header }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in tableData" :key="index">
                    <td v-for="header in headers" :key="header">{{ row[header] }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import VelocityDirectionChart from "@/components/msp/VelocityDirectionChart.vue";
import provinceList from "@/assets/mangrove/province-marine.json";

export default {
  name: "OilspillGraph",
  data: () => ({
    headers: [],
    provinceSelected: null,
    provinceList: provinceList,
    activeTab: "province",
    tableData: [],
    typeSearch: 2,
    title: "Ocean Current Short-Term Prediction (30-Year Training Data)",
    locTrat: { lat: 11.804101, lon: 102.749328 },
    oil_resp: null,
    range: {
      start: undefined,
      end: undefined,
    },
    masks: {
      input: "YYYY-MM-DD",
    },
    locationBound: {
      minLat: 0,
      minLon: 0,
      maxLat: 0,
      maxLon: 0,
    },
    map: undefined,
    gistdaLayerName: ["v_recent_gul6", "recent_gulf", "HF_Stations"],
    webgnomeUrl: process.env.VUE_APP_WEBGNOME_URL,
    isDownload: false,
    oilPolygonArea: null,
    typeCurrent: "ShortTermPrediction",
    itemsCurrent: [
      {
        name: "การพยากรณ์ระยะสั้น (short term prediction)",
        value: "ShortTermPrediction",
      }, // nc
    ],
  }),
  components: { VelocityDirectionChart },
  created() {},
  mounted() {
    this.initDate();
  },
  computed: {
    // a computed getter
  },
  methods: {
    initDate() {
      let self = this;
      let date = new Date();
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);

      self.range = {
        start: date.setDate(date.getDate() - 1),
        end: localISOTime,
      };
    },
    init(map) {
      let self = this;
      self.map = map;
      self.map.Ui.Toolbar.visible(false);
      self.map.Ui.LayerSelector.visible(false);
      self.map.Ui.Fullscreen.visible(false);

      self.map.Event.bind("overlayChange", function (overlay) {
        if (
          overlay instanceof window.longdo.Polygon &&
          !(overlay instanceof window.longdo.Rectangle)
        ) {
          self.setPolygonBound(overlay);
        }
      });

      self.addWMS("geonode:convex_hull");

      self.gistdaLayerName.forEach((name) => {
        self.map.Layers.add(
          new window.longdo.Layer(name, {
            type: window.longdo.LayerType.WMS,
            url: "https://ocean.gistda.or.th/geoserver/coastalradar/wms",
            extraQuery: "buffer=16",
          })
        );
      });
    },
    addWMS(layerName) {
      this.map.Layers.add(
        new window.longdo.Layer(layerName, {
          type: window.longdo.LayerType.WMS,
          url: process.env.VUE_APP_GEOSERVER,
        })
      );
    },
    mapBound(val) {
      this.map.bound(val);
      this.map.resize();
    },
    async downloadGeoJsonFile() {
      this.isDownload = true;
      // const { getUrl, accessToken } = await this.getDataFromBBox();
      const { getUrl } = await this.getDataFromBBox();
      // this.fetchAndDownloadGeoJson(getUrl, accessToken);
      this.fetchAndDownloadGeoJson(getUrl);
    },
    async createGraph() {
      let self = this;

      this.isDownload = true;
      // const data = await this.getDataFromBBox();
      let getUrl;
      if (this.activeTab === "province") {
        getUrl = `${
          process.env.VUE_APP_OILSPILL_API_SHORT_TERM
        }CurrentPredict30ML/${self.formatDate(self.range.start)}/${self.formatDate(
          self.range.end
        )}/${this.provinceSelected.id}/csv`;
      } else if (this.activeTab === "bbox") {
        getUrl = `${
          process.env.VUE_APP_OILSPILL_API_SHORT_TERM
        }CurrentPredict30ML/${self.formatDate(self.range.start)}/${self.formatDate(
          self.range.end
        )}/${self.locationBound.minLon},${self.locationBound.minLat},${
          self.locationBound.maxLon
        },${self.locationBound.maxLat}/csv`;
      }
      // this.getCsvData(getUrl, data.accessToken);
      this.getCsvData(getUrl);
    },
    // async getCsvData(getUrl, accessToken) {
    async getCsvData(getUrl) {
      try {
        // Fetch the GeoJSON data
        const getResponse = await fetch(getUrl, {
          method: "GET",
          headers: {
            // Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        });

        // Check if the response is OK
        if (!getResponse.ok) {
          throw new Error(`Error: ${getResponse.status} - ${getResponse.statusText}`);
        }

        // Parse the response as csv
        const csvData = await getResponse.text();
        this.oil_resp = csvData;

        this.isDownload = false;
      } catch (error) {
        // Handle errors
        console.error("Failed to fetch and download CSV:", error.message);
      }
    },
    async getDataFromBBox() {
      // 20250205 => No need to login from api/v2/login

      // let self = this;
      // // Step 1: Login to get the access token
      // const loginUrl = `${process.env.VUE_APP_OILSPILL_API_SHORT_TERM}api/v2/login`;
      // const loginPayload = {
      //   username: process.env.VUE_APP_OILSPILL_USERNAME,
      //   password: process.env.VUE_APP_OILSPILL_PASSWORD,
      // };

      try {
        // // Login request
        // const loginResponse = await fetch(loginUrl, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(loginPayload),
        // });

        // if (!loginResponse.ok) {
        //   throw new Error(`Login failed: ${loginResponse.statusText}`);
        // }

        // const loginData = await loginResponse.json();
        // const accessToken = loginData.access_token;

        // Step 2: Use the access token to make the GET call
        let getUrl;
        if (this.activeTab === "province") {
          getUrl = `${
            process.env.VUE_APP_OILSPILL_API_SHORT_TERM
          }CurrentPredict30ML/${self.formatDate(
            self.range.start
          )}/${self.formatDate(self.range.end)}/${this.provinceSelected.id}/geojson`;
        } else if (this.activeTab === "bbox") {
          getUrl = `${
            process.env.VUE_APP_OILSPILL_API_SHORT_TERM
          }CurrentPredict30ML/${self.formatDate(
            self.range.start
          )}/${self.formatDate(self.range.end)}/${self.locationBound.minLon},${
            self.locationBound.minLat
          },${self.locationBound.maxLon},${self.locationBound.maxLat}/geojson`;
        }

        // return { getUrl, accessToken };
        return { getUrl };
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
    // async fetchAndDownloadGeoJson(getUrl, accessToken) {
    async fetchAndDownloadGeoJson(getUrl) {
      try {
        // Fetch the GeoJSON data
        const getResponse = await fetch(getUrl, {
          method: "GET",
          headers: {
            // Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        });

        // Check if the response is OK
        if (!getResponse.ok) {
          throw new Error(`Error: ${getResponse.status} - ${getResponse.statusText}`);
        }

        // Parse the response as JSON
        const geoJsonData = await getResponse.json();

        // Convert GeoJSON to a Blob
        const blob = new Blob([JSON.stringify(geoJsonData)], {
          type: "application/json",
        });

        // Create a link element for downloading
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "data.geojson"; // Name of the downloaded file
        document.body.appendChild(link); // Append link to the body
        link.click(); // Programmatically click the link to trigger the download
        document.body.removeChild(link); // Remove the link element after download

        this.isDownload = false;
        console.log("GeoJSON file has been downloaded successfully.");
      } catch (error) {
        // Handle errors
        console.error("Failed to fetch and download GeoJSON:", error.message);
      }
    },
    drawRectangle() {
      let self = this;
      let location = self.map.location();
      self.map.Overlays.remove(self.oilPolygonArea);
      self.oilPolygonArea = new window.longdo.Rectangle(
        {
          lon: location.lon,
          lat: location.lat,
        },
        {
          width: 0.2,
          height: 0.2,
        },
        {
          editable: true,
          lineColor: "rgba(0, 0, 255, 0.9)",
          fillColor: "rgba(0, 0, 255, 0.3)",
        }
      );

      self.map.Overlays.add(self.oilPolygonArea);
      self.setPolygonBound(self.oilPolygonArea);
    },
    checkTypeFile(val) {
      const fileTypeMap = {
        WindDB: ".wnd",
        WindModel: ".wnd",
        // Add more specific mappings if needed
      };
      return fileTypeMap[val] || ".nc"; // Default to ".nc" if the value is not found in the map
    },
    setPolygonBound(obj) {
      let self = this;
      let tempPolygonBound = obj.location();
      self.locationBound = {
        minLat: tempPolygonBound[2].lat.toFixed(6),
        minLon: tempPolygonBound[0].lon.toFixed(6),
        maxLat: tempPolygonBound[0].lat.toFixed(6),
        maxLon: tempPolygonBound[1].lon.toFixed(6),
      };
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    formatDate(date) {
      return (
        [
          date.getFullYear(),
          this.padTo2Digits(date.getMonth() + 1),
          this.padTo2Digits(date.getDate()),
        ].join("-") +
        " " +
        [
          this.padTo2Digits(date.getHours()),
          this.padTo2Digits(date.getMinutes()),
          this.padTo2Digits(date.getSeconds()),
        ].join(":")
      );
    },
    parseCsv(csvString) {
      const rows = csvString.split("\n");
      this.headers = rows[0].split(",").map((header) => header.trim());
      this.tableData = rows.slice(1).map((row) => {
        const values = row.split(",");
        return this.headers.reduce((acc, header, index) => {
          acc[header] = values[index]?.trim();
          return acc;
        }, {});
      });
    },
  },
  watch: {
    oil_resp: {
      immediate: true,
      handler(value) {
        if (value) {
          this.parseCsv(value);
        }
      },
    },
  },
};
</script>

<style scoped>
.header {
  color: white;
}

#map {
  width: 100%;
  height: 100%;
}

#map-result {
  width: 100%;
  height: 100%;
}

.cell-title {
  font-size: 12px;
}

.cell-value {
  color: red;
}
</style>
